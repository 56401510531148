import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { ColorSwatch } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Color Swatch",
  "componentId": "colorswatch",
  "description": "Color swatches display a color for a user to choose from.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Documentation in progress-----------------------`}</strong></p>
    <h2>{`Variants`}</h2>
    <PatternExample example={<ColorSwatch color="#885F99" mdxType="ColorSwatch" />} mdxType="PatternExample" />
    <h2>{`State`}</h2>
    <PatternExample example={<ColorSwatch color="#885F99" active={true} mdxType="ColorSwatch" />} mdxType="PatternExample" />
    <h2>{`Usage`}</h2>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      